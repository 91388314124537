import { ReviewComment } from "./ReviewComment";

export const ReviewSettings = {
  pageSize: 5,
  pageNumber: 1,
  filterRating: [1, 2, 3, 4, 5],
};

export type Review = {
  livePreviewHash?: string;
  contentTypeUid?: string;
  localeCode: string;
  currencyCode: string;
  displayName: string;
  reviewComments: ReviewComment[];
};
