<template>
  <ErrorBoundary>
    <component
      :is="pageComponent"
      v-if="pageComponent && pageContent"
      v-bind="pageContent"
    />
  </ErrorBoundary>
</template>

<script lang="ts">
import Vue from "vue";
import { H3Error } from "h3";
import { FetchError } from "ohmyfetch";
import { InitPageParams, initPage } from "~~/lib/utils/initPage";
import DestinationPage from "~~/components/DestinationPage/DestinationPage.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import ModularPage from "~~/components/ModularPage/ModularPage.vue";
import ETIPage from "~~/components/ETIPage/ETIPage.vue";
import TripPage from "~~/components/TripPage/TripPage.vue";
import ContactUsPage from "~/components/ContactUsPage/ContactUsPage.vue";
import { PageContent, PageResponse, PageType } from "~~/lib/types/Page";
import { measureRenderDuration } from "~~/lib/utils/logger/instrument";
import { performRedirectionForInvalidProduct } from "~~/lib/utils/route/performRedirectionForInvalidProduct";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import FAQPage from "~~/components/FAQPage/FAQPage.vue";
import CategoryPage from "~~/components/CategoryPage/CategoryPage.vue";
import StylePage from "~~/components/StylePage/StylePage.vue";
import ThemePage from "~~/components/ThemePage/ThemePage.vue";
import ClusterPage from "~~/components/ClusterPage/ClusterPage.vue";
import BoatPage from "~~/components/BoatPage/BoatPage.vue";
import DestinationMonthsPage from "~/components/DestinationMonthsPage/DestinationMonthsPage.vue";
import DealPage from "~~/components/DealPage/DealPage.vue";
import DealsPage from "~~/components/DealsPage/DealsPage.vue";
import EngagementPage from "~~/components/EngagementPage/EngagementPage.vue";
import { EngagementPageProps } from "~~/components/EngagementPage/Props";
import { isFullyQualifiedLink } from "~~/lib/utils/link/isFullyQualifiedLink";

const logger = loggerFactory({
  tags: [logTags.Layer.Page],
});

export const pageTypeComponentTypeMap: Record<PageType, string> = {
  [PageType.destination]: "DestinationPage",
  [PageType.modularPage]: "ModularPage",
  [PageType.eti]: "ETIPage",
  [PageType.product]: "TripPage",
  [PageType.contactUs]: "ContactUsPage",
  [PageType.faq]: "FAQPage",
  [PageType.category]: "CategoryPage",
  [PageType.style]: "StylePage",
  [PageType.theme]: "ThemePage",
  [PageType.clusterPage]: "ClusterPage",
  [PageType.boat]: "BoatPage",
  [PageType.destinationMonths]: "DestinationMonthsPage",
  [PageType.deal]: "DealPage",
  [PageType.dealsPage]: "DealsPage",
  [PageType.engagementPage]: "EngagementPage",
};

export default Vue.extend({
  components: {
    ModularPage,
    DestinationPage,
    ETIPage,
    TripPage,
    ErrorBoundary,
    ContactUsPage,
    FAQPage,
    CategoryPage,
    StylePage,
    ThemePage,
    ClusterPage,
    BoatPage,
    DestinationMonthsPage,
    DealPage,
    DealsPage,
    EngagementPage,
  },
  async asyncData({
    redirect,
    params,
    error,
    i18n,
    store,
    route,
    res,
    req,
    $config,
  }): Promise<PageResponse> {
    try {
      if (req.url?.includes("/__webpack_hmr/client")) {
        error({
          message: "Not found",
          statusCode: 404,
        });
        return {};
      }

      const currentPath = params.pathMatch.replace(`/${i18n.locale}`, "");

      const pageType = await $fetch<PageType>("/api/nuxt/global/page-type", {
        params: {
          slug: `/${params.pathMatch}`,
          locale_iso: i18n.localeProperties.iso,
        },
      });

      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: `/${params.pathMatch}`,
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        current_path: currentPath,
        page_type: pageType,
      };

      await Promise.all(initPage(store, requestParams));

      if (!pageType) {
        error({
          message: "Not Found",
          statusCode: 404,
        });
        return {};
      }

      await store.dispatch("page/setPageType", pageType);

      const pageContent = await $fetch<PageContent>(
        `/api/nuxt/${pageType.replace("_", "-")}/page`,
        {
          params: {
            ...requestParams,
          },
        }
      );

      if (
        pageType === "engagement" &&
        (pageContent as EngagementPageProps).externalUrl &&
        isFullyQualifiedLink(
          (pageContent as EngagementPageProps).externalUrl as string
        )
      ) {
        redirect((pageContent as EngagementPageProps).externalUrl as string);
        return {};
      } else {
        await measureRenderDuration(res);

        return {
          pageComponent: pageTypeComponentTypeMap[pageType as PageType],
          pageContent,
        };
      }
    } catch (e) {
      const queryString = Object.keys(route.query)
        .map((key) => `${key}=${route.query[key]}`)
        .join("&");

      if (
        (e as H3Error).statusCode === 404 &&
        (await performRedirectionForInvalidProduct(
          {
            path: `/${params.pathMatch}`,
            locale_code: i18n.locale,
            currency_code: params.currencyCode,
            query: queryString ? `?${queryString}` : "",
          },
          redirect,
          error
        ))
      ) {
        return {};
      }
      const logLevel =
        (e as FetchError).status === 404 || (e as FetchError).statusCode === 404
          ? "warn"
          : "error";
      logger[logLevel]("asyncData failed in _.vue", {
        localeCode: i18n.locale,
        currencyCode: params.currencyCode,
        url: req.url,
        slug: `/${params.pathMatch}`,
        error: e,
      });
      if (e instanceof Error) error(e);
      else error({ message: "Something went wrong with the request." });
      return {};
    }
  },
});
</script>
