/* eslint-disable @typescript-eslint/no-explicit-any */
import { ServerResponse } from "http";
import { loggerFactory, logTags } from "./logger";
import { UnwrapPromise } from "~~/lib/types/Contentstack/System/UnwrapPromise";

const logger = loggerFactory({
  tags: [logTags.Client.NewRelic],
});

// useRuntimeConfig could not be used in this file, hence the constant.
const NEWRELIC_METRICS_ENDPOINT = "https://metric-api.newrelic.com/metric/v1";

/**
 * @function instrument Instruments the results to AWS Xray
 * @param name Name of the function being called
 * @param callback The asynchronous task
 */
export const instrument = <T extends (...args: any[]) => any>(
  name: string,
  asyncTask: T
) => {
  return (...args: Parameters<T>): Promise<UnwrapPromise<ReturnType<T>>> => {
    if (process.env.ENABLE_TRACING_LOGS) {
      if (global && global.newrelic) {
        return global.newrelic.startSegment(name, false, () => {
          global.newrelic.addCustomSpanAttributes({
            functionName: name,
            ...args,
          } as any);

          return asyncTask(...args);
        });
      }
    }

    return asyncTask(...args);
  };
};

export const _pushMetricsToServer = async () => {
  if (!global || !global.newrelic) {
    return () => {};
  }
  const { getSecretByArn } = await import("../aws/getSecret");

  const newrelicLicense = await getSecretByArn<{
    LicenseKey: string;
    NrAccountId: string;
  }>("NEW_RELIC_LICENSE_KEY");

  return async (
    name: string,
    value: number,
    attributes: Record<string, string>
  ) => {
    if (newrelicLicense) {
      try {
        await $fetch(NEWRELIC_METRICS_ENDPOINT, {
          method: "POST",
          headers: {
            "Api-Key": newrelicLicense.LicenseKey,
          },
          body: [
            {
              metrics: [
                {
                  name,
                  type: "gauge",
                  value,
                  timestamp: Date.now(),
                  attributes,
                },
              ],
            },
          ],
        });
      } catch (e) {
        logger.warn("Exception in pushing mertics to server", {
          metricName: name,
          metricValue: value,
          error: e,
        });
        // swallow error here, since we don't want lambda to fail when there is an instrumentation issue
      }
    }
  };
};

export const measureRenderDuration = async (res: ServerResponse) => {
  const originalEnd = res.end.bind(res);
  const renderStart = Date.now();
  const pushMetrics = await _pushMetricsToServer();
  res.end = <typeof res.end>((...args: Parameters<typeof res.end>) => {
    const renderEnd = Date.now();

    pushMetrics(`Custom/Nebula/RenderDuration`, renderEnd - renderStart, {
      functionArn: process.env.NUXT_LAMBDA_FUNCTION_ARN || "",
    })?.catch((err) =>
      logger.warn("unable to push metrics", {
        error: err,
        functionName: "measureRenderDuration",
      })
    );

    originalEnd.bind(res)(...args);
  });
};
