// Number indicates Elements ID
export enum ThemeID {
  Overland = 1,
  ShortBreaks = 2,
  Family = 4,
  Sailing = 5,
  FestivalsAndEvents = 6,
  Wildlife = 10,
  WalkingTrekking = 16,
  Cycling = 17,
  Explorer = 18,
  Food = 88,
  Polar = 94,
  WomensExpedition = 95,
  Expedition = 120,
  AdventureCruising = 125,
  ActiveAdventures = 127,
  _18to29s = 128,
  _18to35s = 133,
  IntrepidRetreats = 130,
  WinterActive = 131,
  MultiActiveAdventures = 132,
}

export type Theme = {
  title: string;
  id: ThemeID;
};

export const ThemeMapByThemeID = Object.entries(ThemeID).reduce(
  (acc, [key, val]) => ({ ...acc, [val]: { id: val, title: key } }),
  {} as Record<ThemeID, Theme>
);

export const ThemeMapByThemeTitle = Object.entries(ThemeID)
  .filter(([, value]) => typeof value !== "string")
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
