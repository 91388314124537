/**
 * Uses a Date object to calculate a string in format YYYY-MM, with leading 0 for month
 *
 * @param {Date} targetDate The date to format
 * @returns A string in format YYYY-MM
 */
export const getDateAsAvailabilitiesStartDateString = (
  targetDate: Date
): string => {
  if (!targetDate) {
    return "";
  }

  try {
    const year = targetDate.getFullYear();
    const month = String(targetDate.getMonth() + 1).padStart(2, "0");
    const day = ("0" + new Date().getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  } catch (e) {
    return "";
  }
};
