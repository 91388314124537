import { DecoratorOptions } from "~~/lib/types/Options";
import { ClientOptions } from "~~/server/clients/contentstack/Client";

export const contentstackClientOptionDecorator = (
  decoratorOptions: Pick<DecoratorOptions, "livePreviewHash" | "contentTypeUid">
): ClientOptions | undefined => {
  if (decoratorOptions?.livePreviewHash && decoratorOptions?.contentTypeUid) {
    return {
      livePreviewHash: decoratorOptions?.livePreviewHash,
      contentTypeUid: decoratorOptions?.contentTypeUid,
    };
  }

  return undefined;
};

export const isLivePreviewEnabled = (
  decoratorOptions: Pick<DecoratorOptions, "livePreviewHash" | "contentTypeUid">
): boolean => {
  if (decoratorOptions?.livePreviewHash && decoratorOptions?.contentTypeUid) {
    return true;
  }

  return false;
};
