import { Context } from "@nuxt/types";
import { H3Error } from "h3";
import { loggerFactory, logTags } from "../logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Routing, logTags.Feature.Products],
});

export const performRedirectionForInvalidProduct = async (
  requestParams: {
    path: string;
    locale_code: string;
    currency_code: string;
    query?: string;
  },
  redirect: Context["redirect"],
  error: Context["error"]
): Promise<boolean> => {
  try {
    const redirectionTargetUrl = (await $fetch(
      `/api/nuxt/product/redirect-invalid-product`,
      {
        params: requestParams,
      }
    )) as string | undefined;

    if (redirectionTargetUrl) {
      const queryString = requestParams.query ?? "";
      const finalUrl = redirectionTargetUrl + queryString;
      redirect(301, finalUrl);
      return true;
    }
  } catch (e) {
    logger.info(
      (e as H3Error).statusCode === 404
        ? "Unable to find a valid product to redirect"
        : "Exception in redirection",
      {
        functionName: "performRedirectionForInvalidProduct",
        statusCode: (e as H3Error).statusCode,
        error: e,
      }
    );

    if (e instanceof Error) error(e);
    else
      error({
        message: "Something went wrong in performRedirectionForInvalidProduct",
      });
  }
  return false;
};
