<template>
  <div>
    <component
      :is="pageComponent"
      v-if="pageComponent && pageContent"
      v-bind="pageContent"
    />
    <LivePreviewErrors v-if="errors && errors.length" :errors="errors" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { FetchError } from "ohmyfetch";
import { InitPageParams } from "lib/utils/initPage";
import { pageTypeComponentTypeMap } from "../_.vue";
import DestinationPage from "~~/components/DestinationPage/DestinationPage.vue";
import ModularPage from "~~/components/ModularPage/ModularPage.vue";
import ETIPage from "~~/components/ETIPage/ETIPage.vue";
import FAQPage from "~~/components/FAQPage/FAQPage.vue";
import CategoryPage from "~~/components/CategoryPage/CategoryPage.vue";
import StylePage from "~~/components/StylePage/StylePage.vue";
import ThemePage from "~~/components/ThemePage/ThemePage.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import TripPage from "~~/components/TripPage/TripPage.vue";
import ContactUsPage from "~/components/ContactUsPage/ContactUsPage.vue";
import {
  LivePreviewPageResponse,
  PageContent,
  PageType,
} from "~~/lib/types/Page";
import LivePreviewErrors from "~~/components/LivePreview/LivePreviewErrors.vue";
import { initPage } from "~~/lib/utils/initPage";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import DealPage from "~~/components/DealPage/DealPage.vue";
import DealsPage from "~~/components/DealsPage/DealsPage.vue";
import ClusterPage from "~~/components/ClusterPage/ClusterPage.vue";
import EngagementPage from "~~/components/EngagementPage/EngagementPage.vue";
import BoatPage from "~~/components/BoatPage/BoatPage.vue";

const logger = loggerFactory({
  tags: [logTags.Layer.Page],
});

const LivePreviewErrorMessages = {
  PLEASE_WAIT: "Your page will reload in few seconds. Please wait.",
  PLEASE_RELOAD_PAGE:
    "An expected error has occurred with Live Preview. Please save your work and reload this page using the browser.",
};

export default Vue.extend({
  components: {
    ModularPage,
    DestinationPage,
    ETIPage,
    TripPage,
    ErrorBoundary,
    ContactUsPage,
    FAQPage,
    CategoryPage,
    StylePage,
    ThemePage,
    DealPage,
    DealsPage,
    ClusterPage,
    EngagementPage,
    BoatPage,
    LivePreviewErrors,
  },
  layout: "preview",
  async asyncData({
    params,
    error,
    i18n,
    $config,
    route,
    store,
  }): Promise<LivePreviewPageResponse> {
    try {
      const pathForHeader = params.pathMatch.replace(`/${i18n.locale}`, "");
      if (!$config.public.contentstackEnableLivePreview) {
        error({ statusCode: 404 });
        return {};
      }

      const pageType = route.query.content_type_uid as PageType;

      if (!pageType) {
        return {
          pageContent: undefined,
          pageComponent: undefined,
          errors: [LivePreviewErrorMessages.PLEASE_WAIT],
        };
      }

      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: `/${params.pathMatch}`,
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        live_preview: route.query?.live_preview ?? undefined,
        content_type_uid: route.query?.content_type_uid ?? undefined,
        current_path: pathForHeader,
      };

      await Promise.all(initPage(store, requestParams));

      const pageContent: PageContent = await $fetch(
        `/api/nuxt/${pageType.replace("_", "-")}/page`,
        {
          params: requestParams,
        }
      );

      /**
       * Live Preview bug and message to notify hard reload
       * Bug report case #00020097
       */
      if ((pageContent as unknown as { _in_progress: boolean })._in_progress) {
        return {
          pageContent: undefined,
          pageComponent: undefined,
          errors: [LivePreviewErrorMessages.PLEASE_RELOAD_PAGE],
        };
      }

      return {
        pageComponent: pageTypeComponentTypeMap[pageType],
        pageContent,
        errors: [],
      };
    } catch (e) {
      if ((e as FetchError).name === "FetchError") {
        const fetchError = e as FetchError;
        if (
          fetchError.data.statusCode === 400 &&
          fetchError.data.statusMessage.includes("ValidationError:")
        ) {
          const validationMessage =
            fetchError.data.statusMessage.split("ValidationError:")[1];

          return {
            pageComponent: undefined,
            pageContent: undefined,
            errors: [validationMessage],
          };
        }

        if (
          fetchError.data.statusCode === 500 &&
          fetchError.data.statusMessage.includes("ValidationMessage:")
        ) {
          const validationMessage =
            fetchError.data.statusMessage.split("ValidationMessage:")[1];

          return {
            pageComponent: undefined,
            pageContent: undefined,
            errors: [validationMessage],
          };
        }
      }

      logger.error("asyncData failed in preview/_.vue", e);
      if (e instanceof Error) error(e);
      else error({ message: "Something went wrong with the request." });
      return {};
    }
  },
  mounted() {
    this.$initLivePreview();
  },
});
</script>
