import { DestinationPageProps } from "~~/components/DestinationPage/Props";
import { ModularPageProps } from "~~/components/ModularPage/Props";
import { ETIPageProps } from "~~/components/ETIPage/Props";
import { TripPageProps } from "~~/components/TripPage/Props";
import { ContactUsPageProps } from "~~/components/ContactUsPage/Props";
import { FAQPageProps } from "~~/components/FAQPage/Props";
import { CategoryPageProps } from "~~/components/CategoryPage/Props";
import { DestinationMonthsPageProps } from "~~/components/DestinationMonthsPage/Props";
import { ClusterPageProps } from "~~/components/ClusterPage/Props";
import { DealPageProps } from "~~/components/DealPage/Props";
import { DealsPageProps } from "~~/components/DealsPage/Props";
import { EngagementPageProps } from "~~/components/EngagementPage/Props";
import { BoatPageProps } from "~/components/BoatPage/Props";

export type PageContent =
  | ModularPageProps
  | DestinationPageProps
  | ETIPageProps
  | TripPageProps
  | ContactUsPageProps
  | FAQPageProps
  | CategoryPageProps
  | DestinationMonthsPageProps
  | ClusterPageProps
  | DealPageProps
  | DealsPageProps
  | EngagementPageProps
  | BoatPageProps
  | undefined;

export type PageResponse = {
  pageComponent?: string;
  pageContent?: PageContent;
};

export type LivePreviewPageResponse = {
  errors?: string[];
} & PageResponse;

export enum PageType {
  destination = "destination",
  modularPage = "modular_page",
  eti = "eti",
  product = "product",
  contactUs = "contact_us",
  faq = "faq",
  category = "category",
  style = "style",
  theme = "theme",
  destinationMonths = "destination_months",
  clusterPage = "cluster",
  boat = "boat",
  deal = "deal",
  dealsPage = "deals_page",
  engagementPage = "engagement",
}
