import type {
  Departure as HayabusaDepartureType,
  Product as HayabusaProductType,
  Price as HayabusaPriceType,
} from "@intrepid-travel/hayabusa-data-client/dist/types";
export type HayabusaAvailabilities = string[];

export enum HayabusaAgeType {
  ADULT = "adult",
  CHILD = "child",
}

export enum HayabusaRoomType {
  SINGLE = "single",
  TWIN = "twin",
  MULTI = "multi",
}

export type HayabusaExclusion = {
  amount: number;
  currencyId?: number;
  currencyCode: string;
  elementTitle?: string;
  currencySymbol?: string;
};

export type HayabusaPrice = Omit<HayabusaPriceType, "validTo" | "validFrom"> & {
  attrs: {
    price: number;
    currencyId: number;
    discountedprice: number;
    childprice?: number;
    childTotalPrice?: number;
    childDiscountedprice?: number;
    childDiscountedTotalPrice?: number;
  };
  validTo: string | Date;
  validFrom: string | Date;
};

export type HayabusaRelated = {
  href: string;
  relationship: string;
};

export type HayabusaSingleSupplement = {
  total: number;
  currencyCode: string;
  effectiveTo: string | Date;
  effectiveFrom: string | Date;
  singleSupplementProductId: number;
  attrs: {
    numberOfCustomers: string;
  };
};

export type HayabusaKittyPrice = {
  kittyAmount: number;
  kittyCurrencyCode: string;
};

export type HayabusaDeparture = Omit<
  HayabusaDepartureType,
  "startDate" | "endDate" | "attrs"
> & {
  startDate: string | Date;
  endDate: string | Date;
  attrs: {
    startCity: string;
    startCountry: string;
    endCity: string;
    endCountry: string;
    vehicleName?: string;
    onRequest: boolean;
    roomTypeName?: string;
    canPlaceOnHoldDeparture?: boolean;
    href?: string;
    freeSell?: boolean;
    lateRequest?: boolean;
    bookingClosed?: number;
    onRequestDays?: number;
    onRequestOnly?: boolean;
    relatedTransfers?: HayabusaRelated[];
    onRequestCapacity?: number;
    lastUpdatedDateTime?: string;
    relatedAccommodation?: HayabusaRelated[];
    relatedSingleSupplement?: HayabusaRelated[];
    singleTravellerCompulsorySingleSupFlag?: boolean;
  };
  prices?: HayabusaPrice[] | null;
  product: HayabusaProductType & {
    attrs: {
      productSubType: {
        id: number;
        name: string;
      };
    };
  };
  exclusions: HayabusaExclusion[] | null;
  kitty?: HayabusaKittyPrice | null;
  priceSingleSupplement?: HayabusaSingleSupplement[] | null;
};

export type HayabusaDeparturesDay = HayabusaDeparture[];

export type HayabusaDepartures = {
  departures: {
    [date: string]: HayabusaDeparturesDay;
  };
  numberOfPages: number;
  pageNumber: number;
  totalRecords?: number;
};
