let componentUniqueId = 0;

type componentUniqueIdMixinType = {
  componentUniqueId?: string;
  beforeCreate: () => void;
};

export const componentUniqueIdMixin: componentUniqueIdMixinType = {
  beforeCreate() {
    this.componentUniqueId = componentUniqueId.toString();
    componentUniqueId += 1;
  },
};
