export const isValidPhoneNumber = (
  value: string,
  withCountryCode = true
): boolean => {
  let regex = /^\[?\(?\+?(\d{1,4})?\)?\]?([0-9-.\s()]){4,20}$/;
  if (!withCountryCode) {
    regex = /^([0-9-.\s()]){4,20}$/;
  }
  return regex.test(value);
};
